import './App.css'
import Card from './components/card'
import Labels from './components/labels'
import Snow from './components/snow'

function App() {
    return (
        <div className="App">
            <Card />
            <Labels />
            <Snow />
        </div>
    )
}

export default App
