import React from 'react'
import '../snow.scss'

class Snow extends React.Component {
    render() {
        return (
            <div className="snowContainer">
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
                <div className="snow"></div>
            </div>
        )
    }
}

export default Snow
