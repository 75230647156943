import React from 'react'
import '../labels.css'

class Labels extends React.Component {
    render() {
        return (
            <div className="labels_container">
                <div className="labels_container__title">
                    Fijne feestdagen gewenst van:
                </div>
                <div className="labels_container__labels">
                    <img src="./np-logo.png" alt="Netprofiler"></img>
                    <img src="./cv-logo.png" alt="Clickvalue"></img>
                    <img src="./cnd-logo.png" alt="Cloud Nine Digital"></img>
                    <img src="./ls-logo.png" alt="Leadscope"></img>
                    <img src="./c2b-logo.png" alt="C2B"></img>
                    <img src="./pi-logo.png" alt="PiMarketing"></img>
                </div>
            </div>
        )
    }
}

export default Labels
